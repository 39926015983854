.ReactModal__Overlay {
  @apply z-[2000];
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply max-w-[100vw] scroll-pt-8 overflow-x-hidden scroll-smooth bg-white font-museo selection:bg-ubo-muk lg:scroll-pt-0;
  }

  html {
    @apply max-w-[100vw] overflow-x-hidden text-ubo-taillow;
  }

  section {
    @apply relative snap-start;
  }

  a,
  a:hover {
    @apply no-underline;
  }

  button {
    @apply bg-transparent;
  }

  p {
    @apply font-museo text-base font-light lg:text-xl;
  }

  button:focus,
  input:focus,
  textarea:focus {
    @apply outline-none;
  }

  textarea {
    @apply resize-none;
  }

  footer a:hover {
    @apply text-ubo-muk;
  }

  .container {
    @apply 3xl:!max-w-8xl;
  }
}

@layer components {
  .btn {
    @apply inline-flex items-center rounded-full border-2 border-transparent px-3 py-3 text-center text-base transition-colors md:px-5 md:text-lg lg:px-7 lg:text-xl;
  }

  .btn--sm {
    @apply inline-flex items-center rounded-full border border-transparent px-2 py-2 text-center text-base transition-colors md:px-3 md:text-lg lg:px-5 lg:text-xl;
  }

  .btn--primary {
    @apply border-ubo-whiscash text-ubo-whiscash hover:border-ubo-delibird hover:text-ubo-delibird;
  }

  .btn--alternate {
    @apply border-ubo-whiscash fill-ubo-whiscash text-ubo-whiscash hover:border-ubo-taillow hover:fill-ubo-taillow hover:text-ubo-taillow;
  }

  .btn--primary--filled,
  .btn--blue {
    @apply border-ubo-whiscash bg-ubo-whiscash text-ubo-delibird hover:border-ubo-delibird hover:text-ubo-delibird;
  }

  .btn--dark {
    @apply border-ubo-roggenrola bg-transparent text-ubo-roggenrola hover:bg-ubo-roggenrola hover:text-ubo-delibird;
  }

  .btn--dark--filled {
    @apply rounded-full border border-ubo-taillow bg-ubo-taillow px-7 py-3 text-base font-semibold text-white hover:bg-transparent hover:text-ubo-taillow;
  }

  .btn--support {
    @apply rounded-full border border-ubo-whiscash bg-ubo-whiscash px-7 py-3 text-base font-semibold text-white hover:bg-transparent hover:text-ubo-whiscash;
  }

  .btn--light {
    @apply border-ubo-delibird text-ubo-delibird transition-colors hover:bg-ubo-delibird hover:text-ubo-whiscash;
  }

  .btn--light--filled {
    @apply bg-ubo-delibird text-ubo-whiscash transition-colors hover:bg-ubo-zebstrika;
  }
}

@layer utilities {
  .presentation-section {
    @apply scroll-mb-[20vh] overflow-hidden lg:min-h-screen;
  }

  .presentation-container {
    @apply mx-auto max-w-9xl px-4 lg:px-16;
  }

  .content-4xl {
    @apply children-headings:text-8xl lg:children-headings:text-13xl;
  }

  .content-3xl {
    @apply children-headings:text-5xl sm:children-headings:text-6xl lg:children-headings:text-9xl 2xl:children-headings:text-12xl;
  }

  .content-2xl {
    @apply children-headings:text-5xl sm:children-headings:text-6xl lg:children-headings:mb-16 lg:children-headings:text-7xl xl:children-headings:text-8xl 2xl:children-headings:text-10xl;
  }

  .content-xxl {
    @apply children-headings:text-5xl sm:children-headings:text-6xl lg:children-headings:mb-16 lg:children-headings:text-7xl xl:children-headings:text-8xl 2xl:children-headings:text-9xl;
  }

  .content-xl {
    @apply children-headings:mb-4 children-headings:text-4xl children-p:text-sm sm:children-p:text-base lg:children-headings:text-6xl lg:children-p:text-lg 2xl:children-headings:text-7xl 2xl:children-p:text-xl;
  }

  .content-lg {
    @apply children-headings:text-5xl lg:children-headings:text-6xl;
  }

  .content-md {
    @apply children-headings:text-3xl children-ul:text-xl md:children-headings:text-5xl;
  }

  .content-sm {
    @apply children-headings:mb-2 children-headings:text-xl children-headings:leading-none children-ul:text-xl sm:children-headings:text-2xl md:children-headings:text-3xl lg:children-headings:mb-4;
  }

  .content-xs {
    @apply children-headings:mb-4 children-headings:text-2xl children-headings:leading-[1.5rem];
  }

  .content-quote {
    @apply children-p:text-xl children-p:font-bold children-p:leading-[1.75rem] sm:children-p:leading-[2.5rem] md:children-p:text-4xl md:children-p:leading-[3rem] 2xl:children-p:text-5xl 2xl:children-p:leading-[3.5rem];
  }

  .content-stroke {
    @apply children-em:text-stroke children-em:stroke-dark children-em:not-italic;
  }

  .content-stroke-light {
    @apply children-em:text-stroke children-em:not-italic;
  }

  .content-list-style-checkmarks ul > li {
    @apply relative list-none overflow-visible py-3 pl-6 before:absolute before:-left-[20px] before:top-[14px] before:h-[34px] before:w-[32px] before:bg-[url('/img/check.svg')] before:bg-no-repeat;
  }

  .title {
    @apply font-september;
  }

  .title-stripe {
    @apply text-ubo-whiscash after:absolute after:-right-[1000px] after:bottom-0 after:block after:w-[2000px] after:border-[2px] after:border-ubo-whiscash;
  }

  .glass {
    @apply bg-opacity-20 bg-clip-padding backdrop-blur-xl backdrop-filter;
  }

  .shadow-top {
    box-shadow: 0px -10px 37px 4px rgba(0, 0, 0, 0.44);
  }

  .link-underline {
    border-bottom-width: 0;
    background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
    background-size: 0 3px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    transition: background-size 0.3s ease-in-out;
  }

  .link-underline-blue {
    background-image: linear-gradient(transparent, transparent), linear-gradient(#0007ec, #0007ec);
  }

  .link-underline-light {
    background-image: linear-gradient(transparent, transparent), linear-gradient(#ffffff, #ffffff);
  }

  .link-underline:hover {
    background-size: 100% 3px;
    background-position: 0 100%;
  }

  .link-underline-active {
    background-size: 100% 3px;
    background-position: 0 100%;
  }

  .text-stroke {
    @apply transition-all duration-100;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
    color: transparent;
  }

  .text--stroke {
    @apply transition-all duration-100;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
    color: transparent;
  }

  .stroke-dark {
    -webkit-text-stroke-color: #000;
  }

  .stroke-ubo {
    -webkit-text-stroke-color: #0007ec;
  }

  .text-nostroke {
    -webkit-text-stroke-width: unset;
    -webkit-text-stroke-color: unset;
    color: inherit;
  }

  .text-shadow {
    text-shadow: 1px 4px 3px rgba(0, 0, 0, 0.3);
  }

  .list-after {
    @apply after:absolute after:left-[0.7rem] after:top-8 after:h-[2.5rem] after:border-[7px] after:border-ubo-whiscash after:last-of-type:hidden lg:after:h-[4.5rem];
  }
}

/* ----------START PARSECONTENT---------- */

.parsecontent {
  @apply children-headings:font-september children-headings:font-black;
}

.parsecontent p strong {
  @apply font-black;
}

.parsecontent strong,
.parsecontent b,
.parsecontent h1 strong,
.parsecontent h1 b,
.parsecontent h1 em,
.parsecontent h2 strong,
.parsecontent h2 b,
.parsecontent h2 em,
.parsecontent h3 strong,
.parsecontent h3 b,
.parsecontent h3 em,
.parsecontent h4 strong,
.parsecontent h4 b,
.parsecontent h4 em,
.parsecontent h5 strong,
.parsecontent h5 b,
.parsecontent h5 em {
  @apply font-black;
}

.parsecontent a {
  @apply font-bold underline;
}

.parsecontent a:hover {
  @apply text-ubo-muk;
}

.parsecontent a:hover {
  @apply underline;
}

.parsecontent ul {
  @apply list-disc pl-5;
}

.alignright {
  @apply float-right text-center;
}

.aligncenter {
  @apply mx-auto my-0 block;
}

.wp-caption {
  @apply max-w-full;
}

.wp-caption-text {
  @apply text-center text-base font-bold;
}
/* ----------END PARSECONTENT---------- */

/* ----------START FORM---------- */
.form-modal .form-duuf-group {
  @apply mb-4 text-xl;
}

.form-contact .form-duuf-group {
  @apply mb-10 text-xl md:mb-14;
}

.form-support .form-duuf-group {
  @apply mb-10 text-xl;
}

.form-webinar .form-duuf-input {
  @apply w-full !min-w-max;
}

.form-webinar .form-duuf-choices-input_6 {
  @apply flex flex-col gap-y-2 font-light;
}

.form-webinar .form-duuf-group-submit button {
  @apply !w-[275px] font-bold !text-black sm:text-lg;
}

.form-modal .form-duuf-group {
  @apply col-span-2 sm:col-span-1;
}

.form-modal .form-duuf-submit {
  @apply btn--sm btn--light;
}

.form-contact-detail .form-duuf-group,
.form-download .form-duuf-group {
  @apply mb-10;
}

.form-contact .form-duuf-group-submit {
  @apply mb-2;
}

.form-support > form {
  @apply grid w-full gap-x-8;
}

.form-contact > form {
  @apply grid grid-cols-2 gap-x-8;
}

.form-contact .form-duuf-group-submit {
  @apply flex justify-end;
}

.form-contact .form-duuf-group {
  @apply col-span-2 md:col-span-1;
}

.form-contact .form-duuf-group-textarea {
  @apply col-span-2;
}

.form-duuf-input,
.form-duuf-textarea {
  @apply relative h-12 w-full;
}

.form-download .form-duuf-input,
.form-download .form-duuf-textarea,
.form-contact-detail .form-duuf-input,
.form-contact-detail .form-duuf-textarea {
  @apply mb-7 border-b-2 border-ubo-whiscash bg-transparent px-2 text-ubo-whiscash placeholder:text-ubo-whiscash;
}

.form-modal > form {
  @apply grid grid-cols-2 gap-10;
}

.form-modal .form-duuf-label {
  @apply text-white;
}

.form-modal .form-duuf-input,
.form-modal .form-duuf-textarea {
  @apply min-w-[300px] border-b border-ubo-delibird bg-transparent px-3 text-ubo-delibird placeholder:text-ubo-delibird/70 sm:border-b-2 md:border-b-[3px];
}

.form-contact .form-duuf-input,
.form-contact .form-duuf-textarea,
.form-support .form-duuf-input,
.form-support .form-duuf-textarea,
.form-support .form-duuf-group-select .form-duuf-select__control {
  @apply min-w-[300px] rounded-none !border-b-2 !border-l-0 !border-r-0 !border-t-0 !border-solid border-ubo-delibird !bg-transparent px-3 text-ubo-delibird placeholder:text-ubo-delibird/70 sm:!border-b-[3px];
}

.form-support .form-duuf-group-select .form-duuf-select__control .form-duuf-select__value-container {
  @apply !pl-0;
}

.form-support .form-duuf-group-select .form-duuf-select__control .form-duuf-select__placeholder {
  @apply !text-ubo-delibird/70;
}

.form-support .form-duuf-group-select .form-duuf-select__control .form-duuf-select__indicator-separator {
  @apply hidden;
}

.form-support .form-duuf-group-select .form-duuf-select__single-value {
  @apply !text-ubo-delibird;
}

.form-support .form-duuf-group-consent {
  @apply text-ubo-delibird;
}

.form-support .form-duuf-group-consent a {
  @apply underline hover:text-ubo-delibird/80;
}

.form-contact-detail .form-duuf-textarea {
  @apply min-h-[12rem];
}

.form-contact .form-duuf-group-consent a {
  @apply underline hover:text-ubo-delibird/80;
}

.form-contact-detail .form-duuf-group-consent a {
  @apply underline hover:text-ubo-whiscash;
}

.form-contact .form-duuf-textarea,
.form-support .form-duuf-textarea {
  @apply min-h-[13rem];
}

.form-duuf-file-input {
  @apply text-slate-500 file:mr-4 file:rounded-full file:border-none file:bg-slate-800 file:px-4 file:py-2 file:text-white file:hover:bg-blue-500 file:hover:transition-all;
}

.form-duuf-checkbox {
  @apply mr-4 h-7 w-7 rounded-md border-none bg-slate-100 text-slate-500 focus:border-none focus:outline-none active:border-none active:outline-none;
}

.form-duuf-select__control {
  @apply h-12 !border-none !bg-slate-100 !shadow-none;
}

.form-duuf-select__menu {
  @apply rounded-lg;
}

.form-duuf-select__menu-list {
  @apply px-3 py-0;
}

.form-duuf-select__option {
  @apply my-1 cursor-pointer rounded-lg transition-all;
}

.form-duuf-select__option:first-of-type {
  @apply mt-3;
}

.form-duuf-select__option:last-of-type {
  @apply mb-3;
}

.form-duuf-select__option:active {
  @apply !bg-slate-500 !text-white;
}

.form-duuf-select__option--is-focused {
  @apply !bg-slate-100;
}

.form-duuf-select__option--is-selected {
  @apply !bg-slate-800;
}

.form-duuf-select__input {
  @apply !border-none !shadow-none !outline-none;
}

.form-duuf-select__single-value {
  @apply !left-[12px] !top-[30px] !m-0 !text-slate-800;
}

.form-duuf-select__placeholder {
  @apply !left-[12px] !top-[24px] !m-0 !text-slate-500;
}

.form-duuf-group {
  @apply relative;
}

.form-duuf-error {
  @apply absolute -top-2 right-0 text-sm text-red-500;
}

.form-download .form-duuf-error {
  @apply -top-2 right-0 w-[200%] text-right !text-sm text-red-500;
}

.form-contact-detail .form-duuf-error {
  @apply -top-6 right-0 text-sm text-red-500;
}

.form-contact .form-duuf-error {
  @apply -top-2 right-0 text-sm text-red-500;
}

.form-support .form-duuf-error {
  @apply relative;
}

.form-support .form-duuf-group-submit button {
  @apply h-[45px] w-[179px] rounded-full bg-ubo-delibird text-ubo-whiscash;
}

.form-contact .form-duuf-group-consent .form-duuf-error {
  @apply -top-8;
}

.form-contact .form-duuf-group-consent {
  @apply text-sm lg:text-base;
}

.form-duuf-label {
  @apply px-2;
}

.form-download input::placeholder {
  @apply !text-sm;
}

.form-duuf-group-radio > div {
  @apply ml-2 mt-4 flex;
}

.form-duuf-group-radio > div > div {
  @apply mr-6;
}

.form-download .form-duuf-label {
  @apply text-xl font-bold text-ubo-whiscash;
}

.form-download .form-duuf-submit > button {
  @apply btn btn--primary--filled mt-6;
}

.form-contact-detail .form-duuf-submit > button {
  @apply btn btn--primary--filled;
}

.form-contact .form-duuf-submit > button {
  @apply flex flex-col items-center text-xl text-white before:transition-transform before:content-arrow-light [&:not([disabled])]:hover:before:translate-x-[calc(0%_+_theme(space.2))] [&[disabled]]:after:absolute [&[disabled]]:after:bottom-1 [&[disabled]]:after:left-0 [&[disabled]]:after:select-none [&[disabled]]:after:text-sm [&[disabled]]:after:opacity-0 hover:[&[disabled]]:after:opacity-100  sm:[&[disabled]]:after:!static md:[&[disabled]]:after:-bottom-5 md:[&[disabled]]:after:-left-[20%] md:[&[disabled]]:after:w-[200%] lg:[&[disabled]]:after:bottom-3;
}

.form-duuf-loading {
  @apply absolute left-0 top-0 z-10 h-full w-full bg-transparent;
}

.form-duuf-submit-disabled > button {
  @apply opacity-40;
}

.form-growth-tool {
  @apply mx-auto max-w-screen-lg;
}

.form-growth-tool .form-duuf-group-name {
  @apply !gap-x-4 sm:!gap-x-8;
}

.form-growth-tool .form-duuf-group-name > .form-duuf-group {
  @apply w-1/2;
}

.form-growth-tool .last-step {
  @apply flex flex-col gap-y-6 bg-white p-4 sm:gap-y-8 sm:p-6 xl:p-8;
}

.form-growth-tool .last-step .form-duuf-group {
  @apply !p-0;
}

.form-growth-tool .form-duuf-group-name-first,
.form-growth-tool .form-duuf-group-name-last,
.form-growth-tool .form-duuf-group-text,
.form-growth-tool .form-duuf-group-email,
.form-growth-tool .form-duuf-group-radio {
  @apply w-full bg-white p-6 text-ubo-taillow xl:p-8;
}

.form-growth-tool .form-duuf-group-name input,
.form-growth-tool .form-duuf-group-text input,
.form-growth-tool .form-duuf-group-email input {
  @apply border border-ubo-taillow px-4;
}

.form-growth-tool .form-duuf-group-name label,
.form-growth-tool .form-duuf-group-email label,
.form-growth-tool .form-duuf-group-text label {
  @apply mb-1 block text-lg font-bold;
}

.form-growth-tool .form-duuf-group-radio > div.form-duuf-choices-input_40 {
  @apply flex-col gap-y-4;
}

.form-growth-tool .form-duuf-group-consent {
  @apply text-ubo-taillow;
}

.form-growth-tool .form-duuf-error-radio {
  top: -0.5rem;
  right: 0.75rem;
}

.form-growth-tool .last-step label {
  @apply block !font-museo !text-sm sm:!text-lg;
}

.form-growth-tool .form-duuf-label {
  @apply block px-0 sm:px-2;
}

.form-growth-tool .form-duuf-checkbox {
  @apply mr-3 h-5 w-5 flex-none sm:mr-4 sm:h-6 sm:w-6;
}

.form-growth-tool .form-duuf-group-radio > div {
  @apply flex-col gap-y-4 lg:flex-row;
}

.form-growth-tool .form-duuf-group-radio > label {
  @apply font-september text-lg font-bold sm:text-[24px];
}
.form-growth-tool .form-duuf-label-mandatory {
  @apply hidden;
}

.form-growth-tool .form-duuf-submit {
  @apply flex items-center justify-center;
}

.form-growth-tool .form-duuf-submit button {
  @apply btn btn--light--filled gap-x-4;
}

/* ----------END FORM---------- */

.swiper-button-disabled {
  @apply opacity-50;
}

.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 1.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

a:hover .wave {
  animation: unset;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

#nprogress .bar {
  background: #0007ec !important;
}

#nprogress .spinner-icon {
  border-top-color: #0007ec !important;
  border-left-color: #0007ec !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #0007ec, 0 0 5px #0007ec !important;
}

.marquee {
  position: absolute;
  width: 100vw;
  max-width: 100%;

  @media (min-width: 1024px) {
    height: 400px;
  }

  @media (max-width: 1023px) {
    height: 150px;
  }
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 32s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.custom--select--arrow {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg width='12' height='17' viewBox='0 0 12 17' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M6.7 1C6.7 0.613401 6.3866 0.3 6 0.3C5.6134 0.3 5.3 0.613401 5.3 1L6.7 1ZM5.50503 16.495C5.77839 16.7683 6.22161 16.7683 6.49497 16.495L10.9497 12.0402C11.2231 11.7668 11.2231 11.3236 10.9497 11.0503C10.6764 10.7769 10.2332 10.7769 9.9598 11.0503L6 15.0101L2.0402 11.0503C1.76684 10.7769 1.32362 10.7769 1.05025 11.0503C0.776886 11.3236 0.776886 11.7668 1.05025 12.0402L5.50503 16.495ZM5.3 1L5.3 16H6.7L6.7 1L5.3 1Z' fill='white'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 15px);
  background-position-y: 11px;
}
